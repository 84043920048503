<script setup>
import { ref } from 'vue';

const props = defineProps({
  value: {
    type: [String, Array, File],
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: 'text',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  mask: {
    type: [String, Function],
    default: '',
  },
  isTextarea: {
    type: Boolean,
    default: false,
  },
  isUpload: {
    type: Boolean,
    default: false,
  },
  accept: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  errorMessage: {
    type: String,
    default: '',
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  isSuccessAction: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits(['input', 'blur', 'keydown', 'keyup', 'focusout', 'clicked']);
const isFocused = ref(false);
const inputRef = ref(null);

const selectReadOnlyField = () => {
  emit('clicked');
  if (props.readOnly) isFocused.value = true;
};

const blurInput = () => {
  isFocused.value = false;
};

defineExpose({ inputRef, name: props.name });
</script>

<template>
  <div :class="['input-container', disabled && 'disabled', errorMessage && 'has-error']">
    <slot name="label">
      <p v-if="label" class="input-label">{{ label }}</p>
    </slot>

    <div
      v-click-outside="blurInput"
      :class="[
        'input-wrap',
        isFocused && 'focused',
        isTextarea && 'textarea-input',
        isUpload && 'upload-input',
        errorMessage && 'has-error',
        readOnly && 'readonly',
        isSuccessAction && 'success'
      ]"
      @click="selectReadOnlyField"
    >
      <slot>
        <div class="left-icon">
          <slot name="left-icon"></slot>
        </div>
        <template v-if="isUpload">
          <span class="uploaded-file-name">{{ value || placeholder }}</span>
          <span class="separator"></span>
          <b-upload :accept="accept" class="file-upload-btn" @input="emit('input', $event)">
            <span>Upload</span>
          </b-upload>
        </template>
        <template v-else>
          <textarea
            v-if="isTextarea"
            :value="value"
            :placeholder="placeholder"
            :disabled="disabled"
            :class="[isFocused && 'focused']"
            :readonly="readOnly"
            @focus="
              isFocused = true;
              emit('focus', $event);
            "
            @blur="
              isFocused = false;
              emit('blur', $event);
            "
            @input="emit('input', $event.target.value, $event)"
            @keydown="emit('keydown', $event)"
            @focusout="emit('focusout', $event)"
            maxlength="200" />
          <input
            v-else
            v-mask="mask"
            :value="value"
            :type="type"
            :name="name"
            :placeholder="placeholder"
            :disabled="disabled"
            :readonly="readOnly"
            ref="inputRef"
            @focus="
              isFocused = true;
              emit('focus', $event);
            "
            @blur="
              isFocused = false;
              emit('blur', $event);
            "
            @input="emit('input', $event.target.value, $event)"
            @keydown="emit('keydown', $event)"
            @keyup="emit('keyup', $event)"
            @focusout="emit('focusout', $event)"
          />

          <div v-if="!isTextarea" class="right-icon">
            <slot name="right-icon"></slot>
          </div>
        </template>
      </slot>
    </div>
    <slot name="description"></slot>
    <span v-if="description" class="description">{{ description }}</span>
    <span v-if="errorMessage" class="error-text">{{ errorMessage }}</span>
  </div>
</template>

<style lang="postcss" scoped>
.input-container {
  --border-light: #dadada;
  --border-focus: rgba(47, 90, 137, 0.6);
  --border-disabled: #f0f4f6;
  --label-text: #49525c;
  --text-color-light: #2f5a8999;
  --text-color-dark: #2b3134;
  --placeholder-color: #abb5be;
  position: relative;
  margin-bottom: 16px;
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
.input-wrap {
  position: relative;
  display: flex;
  align-items: center;
  padding: 9px 8px;
  font-size: 14px;
  color: var(--label-text);
  border: 1px solid var(--border-light);
  border-radius: 5px;
  &.focused:not(.textarea-input) {
    border-color: var(--border-focus);
    box-shadow: none;
  }
  &:hover {
    border-color: var(--border-focus);
  }
  &.has-error {
    border-color: #CB0E47;
  }
  &.success {
    border-color: #00853D !important;
  }

  &.readonly {
    cursor: pointer;
  }

  input {
    width: 100%;
    padding: 0 5px;
    font-size: 14px;
    line-height: 20px;
    color: var(--label-text);
    background: none;
    outline: none;
    border: none;
    ::placeholder {
      color: var(--placeholder-color);
    }
    &[readonly] {
      pointer-events: none;
    }
  }

  &.textarea-input {
    display: block;
    padding: 0;
    border: none;
  }
  &.upload-input {
    padding: 7px 8px;
  }
  textarea {
    width: 100%;
    min-height: 100px;
    max-height: 300px;
    padding: 9px 13px;
    font-size: 14px;
    color: var(--label-text);
    border: 1px solid var(--border-light);
    border-radius: 5px;
    outline: none;
    resize: vertical;
    &.focused {
      border-color: var(--border-focus);
      box-shadow: none;
    }
    &:hover {
      border-color: var(--border-focus);
    }
    &:disabled {
      opacity: 0.5;
    }
  }
}
.left-icon,
.right-icon {
  display: flex;
  color: var(--label-text);
}
.input-label {
  margin-bottom: 5px;
  font-size: 16px;
  color: var(--label-text);
  font-weight: 600;
}
.file-upload-btn {
  margin-left: 8px;
  cursor: pointer;
}
.uploaded-file-name {
  margin-right: 8px;
  color: var(--label-text);
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.separator {
  margin-left: auto;
  height: 20px;
  width: 1px;
  background-color: var(--border-light);
  border-radius: 4px;
}
.description {
  font-size: 12px;
  color: var(--text-color-light);
  padding-left: 2px;
  position: relative;
  top: -3px;
    & + .error-text {
      bottom: -10px;
    }
}

.error-text {
  position: absolute;
  bottom: -15px;
  left: 0;
  padding-left: 2px;
  font-size: 10px;
  line-height: 1.5;
  color: #CB0E47;
}
</style>
