import subscriptionApiService from '@/services/api/subscriptions-api-service';
import { useHarbourStore } from '@/stores/harbour-store';
import { defineStore } from 'pinia';

export const useSubscriptionsStore = defineStore('subscriptions-store', {
  state: () => ({
    harbourStore: useHarbourStore(),
    data: [],
    isLoading: false,
  }),
  actions: {
    getParams(params) {
      // NOTE: adding user context to the params
      // * This is a temporary solution until context is accessed by authenticated user in the backend
      return {
        ...params,
        organization_id: this.harbourStore.contextDict.organizationid,
      };
    },
    getPayload(payload, is_update = false) {
      // NOTE: adding user context to the payload
      // * This is a temporary solution until context is accessed by authenticated user in the backend
      const data = {
        ...payload,
        organization_id: this.harbourStore.contextDict.organizationid,
      };
      if (is_update) {
        data.updated_by = this.harbourStore.contextDict.systemuserid;
      } else {
        data.created_by = this.harbourStore.contextDict.systemuserid;
      }
      return data;
    },
    async fetchSubscriptions(params = {}) {
      this.isLoading = true;
      try {
        const res = await subscriptionApiService.listSubscriptions(this.getParams(params));
        this.data = res?.items || [];
        return res;
      } finally {
        this.isLoading = false;
      }
    },
    async addSubscription(payload) {
      this.isLoading = true;
      try {
        const res = await subscriptionApiService.createSubscription(this.getPayload(payload));
        this.data.push(res);
        return res;
      } finally {
        this.isLoading = false;
      }
    },
    async editSubscription(id, payload) {
      this.isLoading = true;
      try {
        const res = await subscriptionApiService.updateSubscription(id, this.getPayload(payload, true));
        const index = this.data.findIndex((item) => item.id === id);
        this.data.splice(index, 1, res);
        return res;
      } finally {
        this.isLoading = false;
      }
    },
    async removeSubscription(id, payload = {}) {
      this.isLoading = true;
      try {
        const res = await subscriptionApiService.deleteSubscription(id, this.getPayload(payload, true));
        this.data = this.data.filter((item) => item.id !== id);
        return res;
      } finally {
        this.isLoading = false;
      }
    },
  },
});
