import { defineStore } from 'pinia';

import { useAgreementEditorStore } from '@/components/AgreementEditor/stores/agreement-editor-store';
import { useCkeditorStore } from '@/components/Ckeditor/stores/ckeditor-store';
import { useSearchStore } from '@/components/Search/stores/search-store';
import { useFoldersMenuStore } from '@/components/Sidebar/stores/folders-menu-store';
import { useDraftsStore } from '@/pages/Drafts/stores/drafts-store';
import { useSettingsPageStore } from '@/pages/Settings/stores/settings-store.js';
import { useTemplatesStore } from '@/pages/Templates/stores/templates-store';
import { useAutomationsStore } from '@/stores/automations-store';
import { useMagicAnnotationsStore } from '@/stores/magic-annotations-store.js';
import { useWorkflowsStore } from '@/stores/workflows-store';
import { useBrandingsStore } from '@/stores/brandings-store';
import { useAnalyticsStore } from './analytics-store';
import { useColumnStore } from './column-store';
import { useDashboardStore } from './dashboard-store';
import actions from './harbour-actions';
import coreActions from './harbour-core-actions';
import * as getters from './harbour-getters';
import { useLibraryStore } from './library-store';

// A global Pinia store to be used as central/global data for the webapp
// Server calls can be made using Vue.prototype.$harbourData
export const useHarbourStore = defineStore('harbour', {
  state: () => ({
    dashboardStore: useDashboardStore(),
    foldersMenuStore: useFoldersMenuStore(),
    libraryStore: useLibraryStore(),
    templatesStore: useTemplatesStore(),
    searchStore: useSearchStore(),
    automationsStore: useAutomationsStore(),
    settingsStore: useSettingsPageStore(),
    agreementEditorStore: useAgreementEditorStore(),
    magicAnnotationsStore: useMagicAnnotationsStore(),
    columnStore: useColumnStore(),
    analyticsStore: useAnalyticsStore(),
    ckeditorStore: useCkeditorStore(),
    draftsStore: useDraftsStore(),
    workflowsStore: useWorkflowsStore(),
    brandingsStore: useBrandingsStore(),

    lastVisibilityChange: Date.now(),

    sectionHeader: {},
    isMobileView: false,

    hasInitialized: false,
    hasInitializedData: false,
    sseActive: false,

    foldersRawData: null,

    currentHTMLPageTitle: document.title,

    // Data stores
    worker: null,
    isWorkerReady: false,
    myAgreements: [],
    myAssets: [],
    myFolders: null,
    folderNames: new Map(),
    myTemplates: [],
    myPendingContracts: [],
    mySignedAgreements: [],
    templateGalleryData: null,
    outstandingPromises: [],
    sseRequestInterval: null,

    // User personalization
    bannerSrc: null,
    titleColor: '',
    logoSrc: null,
    availableOrganizationalThemes: [],
    workspaceCustomProperties: null,

    timestamps: {},

    // Ready checks
    isInitialDataReady: false,
    isMyAgreementsReady: false,
    isMyLinksLoading: false,
    isMyAssetsLoading: true,
    isMySignedAgreementsReady: false,
    isAwaitingMyReviewReady: false,
    isCoreReady: false,
    isFolderDataReady: false,
    isAwaitingMyReviewLoading: false,
    isSignedByMeLoading: false,
    isLinksReady: false,
    assetsLoaded: false,
    folderAssetsPending: new Set(),
    foldersBeingCopied: new Set(),
    copyFolderTimersPerFolderId: {},
    loadedFolders: {},
    expireFolders: 3000 * 60, // 3 minutes
    isEditApprovalFrame: false,

    mySignedAgreementsHbrefSearch: null,

    // Fancy logging
    logger: {},
    loggerStyles: {
      harbourStore:
        'background-color: #2D71AD; margin: 2px; border-radius: 5px; padding: 3px; color: #dadada',
      linkObject:
        'background-color: #03807A; margin: 2px; border-radius: 5px; padding: 3px; color: #dadada',
    },

    // User state
    workerCapable: 'serviceWorker' in navigator,
    contextDict: null,
    savedColumns: [],
    savedSettings: {
      dashboard_agreelinks_state: 'ALL',
      dashboard_view_type: 'defaultview',
    },
    currentFolder: null,
    assetsFolderName: null,
    availableUserGroups: null,

    // Track when data was last pulled
    lastRequestDateTimeEpochMS: null,
    isLoadingOrgTemplates: false,
    isAgreementEditorReusableLinkEnabled: false,
    isAgreementEditorConvertToPdf: false,

    // Profile pictures
    profilePictures: [],

    defaultColDefForGrids: {
      sortable: true,
      resizable: true,
      lockPinned: true,
      editable: false,
      cellClass: 'hrbr-grid-cell',
      headerClass: 'hrbr-align-center',
      menuTabs: ['filterMenuTab'],
      suppressMenu: false,
      filter: 'agMultiColumnFilter',
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      wrapText: true,
    },

    defaultGridOptionsForGrids: {
      suppressColumnMoveAnimation: true,
      pagination: false,
      animateRows: false,
      floatingFiltersHeight: 50,
      suppressMenuHide: true,
      suppressContextMenu: true,
      enableCellTextSelection: true,
      suppressRowClickSelection: true,
      suppressDragLeaveHidesColumns: true,
    },

    mediaTypes: {
      mediasupportedimagetypes: ['bmp', 'png', 'jpg', 'jpeg', 'gif', 'tif', 'ico', 'webp', 'svg'],
      mediasupportedvideotypes: ['mp4', 'avi', 'mov', 'webm'],
      mediasupportedaudiotypes: ['mp3', 'wav', 'aac'],
      mediasupportedbinarydocumenttypes: ['pdf', 'epub'],
      mediasupportedpostscriptdocumenttypes: ['eps'],
      mediasupported3dmodeltypes: ['glb'], //'usdz'
    },

    isResizing: false,
    isShakeHarbour: false,

    // User location within the webapp tracking for realtime
    // e.g.: fodlers, dashboard, which folder etc.
    location: null,
    locationFilter: null,
    previousLocation: null,
    previousFolder: null,
    folderViewers: [],

    // Real time
    realtimeUserColors: {},
    nextSyncTime: null,
    syncUpdateInterval: 1000 * 60 * 5, // 5 minutes
    userLocationRequestTimeout: null,
    showNewVersionAvailable: false,

    lang: 'en', // en, jp
  }),
  getters: {
    ...getters,
  },
  actions: {
    ...actions,
    ...coreActions,
  },
});
