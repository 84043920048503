<script>
import { storeToRefs } from 'pinia';
import emailService from '@/services/services-email-api-service.js';
import { getEmailRequest, getInitials } from '@/utils/helpers/email-input-helpers.js';
import { useHarbourStore } from '@/stores/harbour-store.js';
import linksApiService from '@/services/links-api-service.js';
import { useSettingsPageStore } from '@/pages/Settings/stores/settings-store.js';
import HrbrInput from '@components/ui/HrbrInput.vue';
import LinkCreatedGroupsDropdownMenu from '../Other/LinkCreatedGroupsDropdownMenu.vue';
import LinkCreatedModalQrSection from '../Other/LinkCreatedModalQrSection.vue';
import { isCustomEmailValid } from '@/utils/helpers/input-validation-helpers.js';
import { useDashboardStore } from '@/stores/dashboard-store.js';

export default {
  name: 'HrbrAgreementLinkCreated',

  components: {
    HrbrInput,
    LinkCreatedGroupsDropdownMenu,
    LinkCreatedModalQrSection
  },

  props: {
    shareLink: {
      type: String,
    },
    shareLinkDisplayId: {
      type: String,
    },
    requestLinkTitle: {
      type: String,
    },
    requestTitle: {
      type: String,
    },
    requestDescription: {
      type: String,
    },
    publishLock: {
      type: String,
    },
    publishLockEmailRecipients: {
      type: Array,
      default: () => [],
    },
    publishLockEmailFixedRecipients: {
      type: Array,
      default: () => [],
    },
    emailEnforceSignReviewOrder: {
      type: Boolean,
    },
    activeAgreementData: {
      type: Object,
      default: () => {},
    },
    emailInputData: {
      type: Object,
      default: () => {},
    },
    recipientEmailsToSign: {
      type: Array,
      default: () => [],
    },
    createLinkType: {
      type: String,
    },
    action: {
      type: String,
    },
  },

  setup() {
    const harbourStore = useHarbourStore();
    const dashboardStore = useDashboardStore();
    const settingsPageStore = useSettingsPageStore();
    const { listUserGroups } = storeToRefs(settingsPageStore);
    const { profilePictures } = storeToRefs(harbourStore);
    const { linksSelectedGroups } = storeToRefs(dashboardStore);

    return {
      profilePictures,
      listUserGroups,
      linksSelectedGroups
    }
  },

  data() {
    return {
      qrCodeJsonLoaded: false,
      isQrCodeVisible: false,
      isSendingEmail: false,
      sentEmails: [],
      copyLinkActive: false,
      linkCopied: false,
      sendEmailActive: false,
      emailSent: false,
      closeBtn: {
        show: true,
        disabled: false,
        text: 'Done',
        action: this.closeModal,
      },
      extendedFormOpened: false,
      ccFieldVisible: false,
      bccFieldVisible: false,
      emailForm: {
        emails: [],
        cc: [],
        bcc: [],
        subject: `${this.activeAgreementData?.agreementtitle} - Please review and complete` || '',
        message: 'Please review and complete the agreement.'
      },
      recipientsForms: [],
      emailFormEmails: [],
      selectedGroups: [],
      publishLockEmailFixedRecipientsForDisplay: [],
      publishLockEmailRecipientsVisibleForDisplay: []
    };
  },

  computed: {
    isPublishLockEmails() {
      return this.publishLock === 'EMAILS';
    },

    isPublishLockPublicOrPasscode() {
      return this.publishLock === 'PUBLIC' || this.publishLock === 'PASSCODE';
    },

    publishLockEmailRecipientsVisible() {
      return this.publishLockEmailRecipients.filter((i) => i.recipientisvisible);
    },

    showQrBtn() {
      return {
        show: true,
        disabled: !this.qrCodeJsonLoaded,
        text: 'Show QR',
        iconLeft: 'fa-regular fa-qrcode',
        isFocused: this.isQrCodeVisible,
        action: this.toggleQrCode,
      };
    },

    copyLinkBtn() {
      return {
        show: true,
        disabled: false,
        text: this.linkCopied ? 'Copied!' : 'Copy link',
        iconLeft: 'fa-regular fa-link-simple',
        action: this.copyShareLink,
        styleType: this.copyLinkActive || this.linkCopied || this.publishLock === 'PUBLIC' ? 'primary' : null
      }
    },

    sendEmailBtn() {
      return {
        show: true,
        disabled: this.isSendingEmail,
        loading: this.isSendingEmail,
        text: this.emailSent ? 'Sent' : 'Send email',
        iconLeft: 'fa-regular fa-paper-plane',
        action: this.sendCustomEmailPublicLink,
        styleType: this.sendEmailActive || this.emailForm.emails.length > 0 ? 'primary' : null
      }
    },

    sendAllBtn() {
      return {
        show: true,
        disabled: this.recipientEmailsToSign.length - this.sentEmails.length < 1,
        text: !this.sentEmails.length ? 'Send to all' : 'Send to remaining',
        iconLeft: 'fa-regular fa-paper-plane',
        styleType: 'primary',
        action: () => this.sendEmail(this.allRecipientsEmails),
      }
    },

    groupsDropdown() {
      return {
        show: true,
        side: 'right',
        text: 'Only me',
        iconLeft: 'fa-regular fa-user',
        iconRight: 'fas fa-caret-down',
        hasSelection: !!this.selectedGroups.length,
        component: LinkCreatedGroupsDropdownMenu,
        componentEvents: {
          change: ({ group, value }) => {
            if (group.id === 'me') {
              const promises = [];
              for (const selectedGroup of this.selectedGroups) {
                const linkId = this.shareLinkDisplayId;
                promises.push(linksApiService.removeGroupFromLink(linkId, selectedGroup.id));
              }

              Promise.all(promises);
              this.selectedGroups = [];
            } else {
              const selectedItemIndex = this.selectedGroups.findIndex(item => item.id === group.id);
              if (value && selectedItemIndex === -1) {
                this.selectedGroups.push(group);
                linksApiService.addGroupToLink(this.shareLinkDisplayId, group.id);
              }
              if (!value) {
                this.selectedGroups.splice(selectedItemIndex, 1);
                linksApiService.removeGroupFromLink(this.shareLinkDisplayId, group.id);
              }
            }

            this.linksSelectedGroups = {
              ...this.linksSelectedGroups,
              [this.shareLinkDisplayId]: this.selectedGroups
            };
          }
        }
      }
    },

    allRecipients() {
      return [
        ...this.publishLockEmailFixedRecipients,
        ...this.publishLockEmailRecipientsVisible,
      ];
    },

    allRecipientsEmails() {
      const recipientsEmails = this.publishLockEmailRecipientsVisible.map(
        (recipient) => recipient.recipientemail,
      );
      const recipientsFixedEmails = this.publishLockEmailFixedRecipients.map(
        (recipient) => recipient.destinationemail,
      );
      return [...recipientsEmails, ...recipientsFixedEmails];
    },

    allRecipientsEmailsSent() {
      return this.allRecipientsEmails.every((email) => this.sentEmails.includes(email));
    },

    showSendToAll() {
      return !this.emailEnforceSignReviewOrder && this.allRecipients.length > 1;
    },

    modalTitle() {
      if (this.action === 'qr_code') {
        return `QR Code: ${this.requestLinkTitle}`;
      }

      return 'Final step: send to signers';
    },
  },

  methods: {
    createCustomEmailsForm() {
      this.recipientsForms = this.allRecipients.map(recipient => ({
        subject: `${this.activeAgreementData?.agreementtitle} - Please review and complete` || '',
        message: 'Please review and complete the agreement.'
      }));
    },

    async copyShareLink() {
      try {
        await navigator.clipboard.writeText(this.shareLink);

        this.$buefy.toast.open({
          message: 'Link copied to clipboard',
          type: 'is-success',
          position: 'is-top',
          duration: 3500,
        });
        this.linkCopied = true;

        setTimeout(() => this.linkCopied = false, 3000);
      } catch (e) {
        console.error(e);
      }
    },

    toggleQrCode() {
      this.isQrCodeVisible = !this.isQrCodeVisible;
      if (this.isQrCodeVisible) {
        setTimeout(() => {
          this.$refs.qrSection.qrCodePanel.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 100);
      }
    },

    async sendEmail(emails = [], index, recipient) {
      if (this.isSendingEmail) return;
      if (recipient) recipient.isSendingEmail = true;
      try {
        const customEmailData = this.recipientsForms[index];
        const emailRequest = getEmailRequest(this.shareLinkDisplayId, { toEmails: emails }, customEmailData?.message || null, 'links:sign_request', customEmailData?.subject || null);
        await emailService.sendEmail(emailRequest);

        if (recipient) recipient.emailFormVisible = false;
      } catch (e) {
        return;
      } finally {
        if (recipient) recipient.isSendingEmail = false;
      }
      this.updateSentEmails(emails);
      this.$buefy.toast.open({
        message: '<i class="fa-regular fa-check"></i> Message was sent successfully',
        type: 'is-success',
        position: 'is-top',
        duration: 3500,
      });
    },

    async sendCustomEmailPublicLink() {
      const isEmailValid = isCustomEmailValid({
        toEmails: this.emailForm.emails,
        ccEmails: this.emailForm.cc,
        bccEmails: this.emailForm.bcc,
        subject: this.emailForm.subject,
        message: this.emailForm.message
      });

      if (!isEmailValid) return;

      this.isSendingEmail = true;

      try {
        const emailRequest = getEmailRequest(
          this.shareLinkDisplayId,
          {
            toEmails: this.emailForm.emails,
            ccEmails: this.emailForm.cc,
            bccEmails: this.emailForm.bcc,
          },
          this.emailForm.message,
          'links:sign_request',
          this.emailForm.subject
        );
        await emailService.sendEmail(emailRequest);

        this.$buefy.toast.open({
          message: '<i class="fa-regular fa-check"></i> Message was sent successfully',
          type: 'is-success',
          position: 'is-top',
          duration: 3500,
        });

        this.emailSent = true;
        this.extendedFormOpened = false;
      } catch (e) {
        this.$buefy.toast.open({
          message: 'Can not send message at the moment. Please, try again later',
          type: 'is-danger',
          position: 'is-top',
          duration: 3500,
        });
      } finally {
        this.isSendingEmail = false;
      }
    },

    updateSentEmails(emails = []) {
      const sentEmails = [...this.sentEmails, ...emails];
      const sentEmailsUnique = [...new Set(sentEmails)];
      this.sentEmails = sentEmailsUnique;
    },

    getRecipientSendBtn(recipient, index, type = 'tertiary') {
      const email = recipient.destinationemail || recipient.recipientemail;
      return {
        show: true,
        text: 'Send',
        loading: recipient.isSendingEmail,
        styleType: type,
        iconLeft: type === 'primary' ? 'fa-regular fa-paper-plane' : '',
        action: () => this.sendEmail([email], index, recipient)
      }
    },

    getProfilePic(email) {
      return this.profilePictures?.find((pic) => pic.email === email)?.image;
    },

    getUserInitials(name, email) {
      return getInitials(name, email);
    },

    async getLinkGroups() {
      try {
        const { items } = await linksApiService.listLinkGroups(this.shareLinkDisplayId);
        this.selectedGroups = items.map(item => {
          return this.listUserGroups.find(groupItem => groupItem.id === item.group_id);
        })
      } catch (e) {
        console.error(e);
      }
    },

    showRecipientEmailForm(recipient) {
      recipient.emailFormVisible = !recipient.emailFormVisible;
    },

    closeModal() {
      this.$parent.close();
    },
    setQRLoaded() {
      this.qrCodeJsonLoaded = true;
    },
    scrollToBottom(isDropdownOpened) {
      if (isDropdownOpened) {
        setTimeout(() => {
          this.$refs.scrollableContainer.scrollTo({ top: this.$refs.scrollableContainer.scrollHeight, behavior: 'smooth' });
        });
      }
    }
  },

  created() {
    this.publishLockEmailFixedRecipientsForDisplay = this.publishLockEmailFixedRecipients.map(recipient => ({
      ...recipient,
      emailFormVisible: false,
      isSendingEmail: false
    }));
    this.publishLockEmailRecipientsVisibleForDisplay = this.publishLockEmailRecipientsVisible.map(recipient => ({
      ...recipient,
      emailFormVisible: false,
      isSendingEmail: false
    }));

    if (this.action === 'qr_code') {
      this.isQrCodeVisible = true;
    }

    this.createCustomEmailsForm();
    this.getLinkGroups();
  },
};
</script>

<template>
  <div
    class="hrbr-agreement-link-created-modal modal-card hrbr-modal"
    id="hrbr-agreement-link-created-modal"
  >
    <header class="modal-card-head">
      <p class="hrbr-agreement-link-created-modal__modal-card-title modal-card-title">
        <i v-if="!action" class="fa-light fa-paper-plane"></i>
        <span data-testid="success-modal-title">{{ modalTitle }}</span>
      </p>
      <p v-if="!action" class="modal-card-subtitle">Your new agreement is ready!</p>
    </header>

    <section ref="scrollableContainer" class="hrbr-agreement-link-created-modal__modal-card-body modal-card-body">
      <div
        class="hrbr-agreement-link-created-modal__share-email-section modal-section"
        v-if="isPublishLockEmails"
      >
        <div class="hrbr-agreement-link-created-modal__section-title">
          <span class="title">Share by email</span>
        </div>

        <div
          v-if="publishLockEmailFixedRecipients.length || publishLockEmailRecipientsVisible.length"
          class="hrbr-agreement-link-created-modal__share-email"
        >
          <div
            :class="[
              { 'hrbr-agreement-link-created-modal__share-email-list--with-order': emailEnforceSignReviewOrder },
              'hrbr-agreement-link-created-modal__share-email-list'
            ]"
          >
            <div
              v-for="(recipient, idx) in publishLockEmailFixedRecipientsForDisplay"
              :key="idx"
              :class="['hrbr-agreement-link-created-modal__share-email-item', {'with-email-form': recipient.emailFormVisible}]"
            >
              <div class="hrbr-agreement-link-created-modal__share-email-item-wrap">
                <div
                  v-if="emailEnforceSignReviewOrder"
                  class="hrbr-agreement-link-created-modal__share-email-col"
                >
                  <span class="order">{{ idx + 1 }}</span>
                </div>
                <div class="hrbr-agreement-link-created-modal__share-email-col">
                  <div class="user-icon">
                    <img
                      v-if="getProfilePic(recipient.destinationemail)"
                      :src="`data:image/png;base64,${getProfilePic(recipient.destinationemail)}`"
                      class="signer-image"
                    />
                    <div
                      v-else
                      class="photo-initials"
                    >
                      {{ getUserInitials(recipient.displayname, recipient.destinationemail) }}
                    </div>
                  </div>
                </div>
                <div class="hrbr-agreement-link-created-modal__share-email-col">
                  <p class="name">{{ recipient.displayname }}</p>
                  <p class="email">{{ recipient.destinationemail }}</p>
                </div>
                <div class="hrbr-agreement-link-created-modal__share-email-col with-customize">
                  <div
                    v-if="sentEmails.includes(recipient.recipientemail)"
                    class="content-block__email-sent"
                  >
                    <i class="fa-regular fa-check"></i>
                    Sent
                  </div>
                  <template v-else>
                    <div
                      class="hrbr-agreement-link-created-modal__extended-email"
                      @click="showRecipientEmailForm(recipient)"
                    >
                      Customize
                    </div>
                    <HrbrButton
                      class-name="content-block__btn send-email-btn"
                      :button="getRecipientSendBtn(recipient, idx)"
                    />
                  </template>
                </div>
              </div>
              <div
                v-if="recipient.emailFormVisible"
                class="hrbr-agreement-link-created-modal__share-email-item-email-form"
              >
                <hrbr-input
                  v-model="recipientsForms[idx].subject"
                  label="Subject"
                />

                <hrbr-input
                  v-model="recipientsForms[idx].message"
                  label="Message"
                  is-textarea
                />
              </div>
            </div>

            <div
              v-for="(recipient, idx) in publishLockEmailRecipientsVisibleForDisplay"
              :key="recipient.originalindex"
              :class="['hrbr-agreement-link-created-modal__share-email-item', {'with-email-form': recipient.emailFormVisible}]"
            >
              <div class="hrbr-agreement-link-created-modal__share-email-item-wrap">
                <div
                  v-if="emailEnforceSignReviewOrder"
                  class="hrbr-agreement-link-created-modal__share-email-col"
                >
                  <span class="order">{{ idx + 1 + publishLockEmailFixedRecipients.length }}</span>
                </div>
                <div class="hrbr-agreement-link-created-modal__share-email-col">
                  <div class="user-icon">
                    <img
                      v-if="getProfilePic(recipient.recipientemail)"
                      :src="`data:image/png;base64,${getProfilePic(recipient.recipientemail)}`"
                      class="signer-image"
                    />
                    <div
                      v-else
                      class="photo-initials"
                    >
                      {{ getUserInitials(recipient.recipientfullname, recipient.recipientemail) }}
                    </div>
                  </div>
                </div>
                <div class="hrbr-agreement-link-created-modal__share-email-col">
                  <p class="name">{{ recipient.recipientfullname }}</p>
                  <p class="email">{{ recipient.recipientemail }}</p>
                </div>
                <div class="hrbr-agreement-link-created-modal__share-email-col with-customize">
                  <template v-if="!emailEnforceSignReviewOrder">
                    <div
                      v-if="sentEmails.includes(recipient.recipientemail)"
                      class="hrbr-agreement-link-created-modal__email-sent"
                    >
                      <i class="fa-regular fa-check"></i>
                      Sent
                    </div>
                    <template v-else>
                      <div
                        v-if="recipientEmailsToSign.includes(recipient.recipientemail)"
                        class="hrbr-agreement-link-created-modal__extended-email"
                        @click="showRecipientEmailForm(recipient)"
                      >
                        Customize
                      </div>

                      <HrbrButton
                        class-name="hrbr-agreement-link-created-modal__list-btn send-email-btn"
                        :button="getRecipientSendBtn(recipient, idx + publishLockEmailFixedRecipients.length)"
                      />
                    </template>
                  </template>

                  <template v-else>
                    <div
                      v-if="!recipientEmailsToSign.includes(recipient.recipientemail)"
                      class="hrbr-agreement-link-created-modal__queue-badge"
                    >
                      <i class="fa-regular fa-clock"></i>
                      Queued for delivery
                      <b-tooltip
                        label="Will be sent automatically after previous recipient signed"
                        type="is-light"
                        position="is-left"
                      >
                        <i class="fa-regular fa-circle-info"></i>
                      </b-tooltip>
                    </div>
                    <div
                      v-else-if="sentEmails.includes(recipient.recipientemail)"
                      class="hrbr-agreement-link-created-modal__email-sent"
                    >
                      <i class="fa-regular fa-check"></i>
                      Sent
                    </div>
                    <template v-else>
                      <div
                        v-if="recipientEmailsToSign.includes(recipient.recipientemail)"
                        class="hrbr-agreement-link-created-modal__extended-email"
                        @click="showRecipientEmailForm(recipient)"
                      >
                        Customize
                      </div>
                      <HrbrButton
                        class-name="hrbr-agreement-link-created-modal__list-btn send-email-btn"
                        :button="getRecipientSendBtn(recipient, idx + publishLockEmailFixedRecipients.length, 'primary')"
                      />
                    </template>
                  </template>
                </div>
              </div>

              <div
                v-if="recipient.emailFormVisible"
                class="hrbr-agreement-link-created-modal__share-email-item-email-form"
              >
                <hrbr-input
                  v-model="recipientsForms[idx + publishLockEmailFixedRecipients.length].subject"
                  label="Subject"
                />

                <hrbr-input
                  v-model="recipientsForms[idx + publishLockEmailFixedRecipients.length].message"
                  label="Message"
                  is-textarea
                />
              </div>
            </div>
          </div>

          <div
            v-if="allRecipientsEmailsSent || showSendToAll"
            class="hrbr-agreement-link-created-modal__share-email-send-all-wrap"
          >
            <div
              class="hrbr-agreement-link-created-modal__email-sent"
              v-if="allRecipientsEmailsSent"
            >
              <i class="fa-regular fa-check"></i> All emails sent
            </div>

            <div class="hrbr-agreement-link-created-modal__share-email-send-all" v-else>
              <HrbrButton
                v-if="showSendToAll"
                class-name="hrbr-agreement-link-created-modal__send-all-btn"
                :button="sendAllBtn"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="hrbr-agreement-link-created-modal__share-link-section modal-section">
        <div class="hrbr-agreement-link-created-modal__share-link">
          <hrbr-input
            v-model="shareLink"
            label="Get shareable link"
            :is-success-action="linkCopied"
            read-only
            @focus="
              copyLinkActive = true;
            "
            @blur="() => {if (this.publishLock !== 'PUBLIC') this.copyLinkActive = false;}"
            @clicked="copyShareLink"
          >
            <template #left-icon>
              <i class="fa-regular fa-globe"></i>
            </template>
            <template #description>
              <span class="description">
                Click to copy link
              </span>
            </template>
          </hrbr-input>

          <HrbrButton
            class-name="hrbr-agreement-link-created-modal__share-section-btn modal-btn"
            :button="copyLinkBtn"
          />

          <HrbrButton
            class-name="hrbr-agreement-link-created-modal__share-section-btn modal-btn"
            :button="showQrBtn"
          />
        </div>

        <LinkCreatedModalQrSection
          ref="qrSection"
          :is-opened="isQrCodeVisible"
          :share-link="shareLink"
          @qr-loaded="setQRLoaded"
        />
      </div>

      <div
        class="hrbr-agreement-link-created-modal__create-email-section modal-section"
        v-if="isPublishLockPublicOrPasscode"
      >
        <div class="hrbr-agreement-link-created-modal__send-email-group">
          <div class="hrbr-agreement-link-created-modal__email-input autocomplete">
            <EmailInput
              v-model="emailForm.emails"
              :email-store="emailForm.emails"
              placeholder="type email here"
              label="Send by email"
              icon-pack="far"
              icon="envelope"
              maxtags="50"
              :maxlength="500"
              :on-paste-separators="[',', ' ']"
              :results-limit="30"
              :recents-tags-limit="5"
              :with-cc-icons="true"
              ellipsis
              attached
              @email-input-focus="sendEmailActive = true"
              @email-input-blur="sendEmailActive = false"
              @add-cc="ccFieldVisible = true; extendedFormOpened = true;"
              @add-bcc="bccFieldVisible = true; extendedFormOpened = true;"
            />
          </div>

          <div class="hrbr-agreement-link-created-modal__send-email">
            <span
              class="hrbr-agreement-link-created-modal__extended-email"
              @click="extendedFormOpened = !extendedFormOpened"
            >
              Customize emails
            </span>

            <HrbrButton
              class-name="modal-btn"
              :button="sendEmailBtn"
            />
          </div>
        </div>

        <div
          v-show="extendedFormOpened"
          class="hrbr-agreement-link-created-modal__email-extended-form"
        >
          <div v-if="ccFieldVisible" class="autocomplete">
            <EmailInput
              v-model="emailForm.cc"
              :email-store="emailForm.cc"
              icon=""
              placeholder="type email here"
              label="Cc"
              maxtags="50"
              :maxlength="500"
              :on-paste-separators="[',', ' ']"
              :results-limit="30"
              :recents-tags-limit="5"
              ellipsis
              attached
            />
          </div>

          <div v-if="bccFieldVisible" class="autocomplete">
            <EmailInput
              v-if="bccFieldVisible"
              v-model="emailForm.bcc"
              :email-store="emailForm.bcc"
              icon=""
              placeholder="type email here"
              label="Bcc"
              maxtags="50"
              :maxlength="500"
              :on-paste-separators="[',', ' ']"
              :results-limit="30"
              :recents-tags-limit="5"
              ellipsis
              attached
            />
          </div>

          <hrbr-input
            v-model="emailForm.subject"
            label="Subject"
          />

          <hrbr-input
            v-model="emailForm.message"
            label="Message"
            is-textarea
          />
        </div>
      </div>

      <div
        v-if="action !== 'qr_code'"
        class="hrbr-agreement-link-created-modal__groups-section modal-section"
      >
        <div class="group-data">
          <p class="group-data-title">
            People with access
            <span class="new-badge">New</span>
          </p>
          <p class="group-data-text">Group admins can track and view documents in their dashboard</p>
        </div>
        <HrbrButton
          class-name="hrbr-agreement-link-created-modal__dropdown-btn"
          :button="groupsDropdown"
          :component-events="groupsDropdown.componentEvents"
          :component-params="{
            selectedGroups
          }"
          :component="groupsDropdown.component"
          @dropdown-toggle="scrollToBottom"
        >
          <template slot="selected">
            <div class="selection">
              <div class="avatars-list">
                <div
                  v-for="group in selectedGroups"
                  :key="group.id"
                  :style="{backgroundColor: group.bgColor}"
                  class="avatar"
                >
                  <i :class="['fa-light', `fa-${group.icon}`]"></i>
                </div>
              </div>
              <span v-if="selectedGroups.length === 1">
                {{ selectedGroups[0].name }}
              </span>
              <span v-else>
                {{selectedGroups.length}} groups
              </span>
            </div>
          </template>
        </HrbrButton>
      </div>
    </section>

    <footer class="modal-card-foot">
      <HrbrButton
        :button="closeBtn"
      />
    </footer>
  </div>
</template>

<style lang="postcss" scoped>
  @import "@/assets/css/elements/email-autocomplete-new.css";
  @import "@/assets/css/elements/modal.css";

  .hrbr-agreement-link-created-modal {
    height: 640px;

    .modal-section {
      padding: 16px 0 19px 0;
      border-top: 1px solid #dbdbdb;
    }

    &__share-link {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      gap: 16px;
      .input-container {
        width: 100%;
        margin-bottom: 0;
        &:hover .description {
          display: block;
        }
        .description {
          display: none;
          position: absolute;
          top: calc(100% + 1px);
        }
      }
    }
    >>> .input-container {
      --border-light: #dbdbdb;
      --border-focus: #1355FF;
      --label-text: #333;
      --text-color-light: #555;
      --text-color-dark: #333;
      --placeholder-color: #555;
      .input-wrap, .input-wrap textarea {
        border-radius: 8px;
      }
    }
    >>> .section-btn {
      padding: 5px 12px;
      font-size: 14px;
      font-weight: 600;
      color: #333;
      border-radius: 8px;
    }

    .modal-btn {
      >>> .section-btn {
        width: 140px;
      }
    }
    &__send-email-group {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      gap: 16px;
      .label {
        margin-bottom: 5px;
      }
    }
    &__email-input {
      width: 100%;
    }
    &__send-email {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .hrbr-agreement-link-created-modal__email-sent {
        min-width: 100px;
        height: 40px;
        justify-content: flex-end;
      }
    }
    &__extended-email {
      margin-bottom: 4px;
      font-size: 12px;
      line-height: 24px;
      color: #1355FF;
      cursor: pointer;
      text-align: right;
      &:hover {
        color: #0133B7;
      }
    }
    &__email-extended-form {
      margin-top: 16px;
      .autocomplete:not(:last-child) {
        margin-bottom: 16px;
      }
    }
    &__section-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      .title {
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 600;
        color: #333;
      }
    }
    &__share-email-list {
      .hrbr-agreement-link-created-modal__extended-email {
        margin: 0;
      }
    }
    &__share-email-list--with-order {
      .hrbr-agreement-link-created-modal__share-email-item-wrap {
        grid-template-columns: 12px 24px auto max-content;
      }
    }
    &__share-email-item-wrap {
      display: grid;
      grid-template-columns: 24px auto max-content;
      align-items: center;
      gap: 12px;
    }
    &__share-email-item {
      padding: 12px 0;
      .user-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: #D1DBF4;
      }
      .signer-image {
        width: 100%;
        height: auto;
        border-radius: 50%;
      }
      .send-email-btn {
        height: 37px;
      }
      >>> .section-btn {
        font-size: 14px;
      }
      .order {
        font-size: 14px;
        font-weight: 600;
      }
      &.with-email-form:not(:last-child) {
        border-bottom: 1px solid #dbdbdb;
        margin-bottom: 12px;
      }
    }
    &__share-email-item-email-form {
      margin-top: 12px;
      >>> .input-container:last-child {
        margin-bottom: 0;
      }
    }
    &__share-email-col {
      font-size: 12px;
      color: #333;
      word-break: break-all;
      .name {
        font-weight: 600;
      }
      >>> .tooltip-content {
        width: 200px;
        background-color: #fff;
        color: #333;
        white-space: break-spaces;
        &::before {
          border-left-color: #fff !important;
        }
      }
      &.with-customize {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
    &__queue-badge {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 8px 12px;
      font-size: 14px;
      font-weight: 600;
      color: #CCC;
    }
    &__email-sent {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 8px 12px;
      font-size: 14px;
      font-weight: 600;
      color: #00853D;
    }
    &__share-email-send-all-wrap {
      display: flex;
      justify-content: flex-end;
      padding-top: 12px;
    }
    .group-data {
      color: #333;
      &-title {
        display: flex;
        align-items: center;
        gap: 4px;
        font-weight: 600;
      }
      &-text {
        font-size: 12px;
      }
    }
    .new-badge {
      padding: 0 4px;
      font-size: 12px;
      font-weight: 600;
      border-radius: 4px;
      background: #F7D83A;
    }
    &__dropdown-btn {
      min-width: 140px;
      >>> .dropdown {
        right: 0;
        left: unset !important;
      }
    }
    &__groups-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .avatars-list {
        display: flex;
      }
      .avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        i {
          font-size: 9px;
          color: #fff;
        }
        &:not(:last-of-type) {
          margin-right: -13px;
        }
        &:nth-child(4n - 1) {
          margin-right: 0;
        }
        &:nth-child(n + 4) {
          display: none;
        }
      }
      .selection {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      >>> .section-btn {
        width: 100%;
      }
    }
    @media screen and (max-width: 640px) {
      height: 100%;

      &__share-link {
        .modal-btn {
          >>> .section-btn {
            width: auto;
          }
        }
        >>> .section-btn {
          .btn-text {
            display: none;
          }
          .btn-icon-left {
            margin: 0;
          }
        }
      }
      &__dropdown-btn {
        min-width: 125px;
        >>> .dropdown {
          position: absolute !important;
          width: calc(100% + 170px) !important;
        }
      }
      &__share-email-col {
        &.with-customize {
          flex-direction: column;
          gap: 0;
        }
      }
    }
  }
</style>
