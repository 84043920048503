import { computed, ref } from 'vue';
import { useOutcome } from './wf-use-block-outcome.js';

export function useBlock(block, parentBlockRef = null, wf = null) {
  // Default block settings for all blocks
  // Will be overriden by the block definition
  const allBlockSettings = {
    title: null,
    description: null,
    version: '1.0.0',
    state: 'Pending',
  };

  const generateID = () => crypto.randomUUID();

  const friendlyId = ref(`${block.key}1`);
  const id = ref(generateID());
  const selfRef = ref(null);
  const key = ref(block.key);
  const parent = ref(parentBlockRef);
  const path = ref(block.path);
  const parentOutcome = ref(null);
  const workflow = ref(wf);
  const isFromOrgWideGroup = wf.is_org_wide || false;

  // The block's horizontal offset
  const x = ref(null);

  // Self DOM element
  const domElement = ref(null);
  const setDomElement = (element) => {
    domElement.value = element;
  };
  const getDomElement = () => domElement.value;
  const blockWidth = block.blockWidth || 360;
  const blockSpacing = block.blockSpacing || 40;
  const blockHeight = block.blockHeight || 75;

  const level = computed(() => (parent.value ? parent.value.level + 1 : 0));
  const element = ref(null);

  const title = ref(block.title);
  const blockTitle = block.title;
  const indexOfType = ref(null);
  const showLoading = ref(false);

  const isRoot = ref(block.isRoot);
  const setAsRoot = (value) => (isRoot.value = value);

  const isDemo = ref(block.isDemo);
  const quickButtonText = ref(block.quickButtonText);
  const quickButtonAction = ref(block.quickButtonAction);

  const subtitleIcon = ref(block.subtitleIcon);
  const icon = ref(block.icon);
  const lightColor = block.lightColor;
  const darkColor = block.darkColor;
  const thickBorder = block.thickBorder || 3;
  const thinBorder = block.thinBorder || 1;

  // State can be restored from the back end, used for active workflows
  // The internal state is for front end actions only
  const internalState = ref(block.internalState || {});
  const state = ref(block.state);

  const actions = ref(block.actions);
  const inputs = ref([]);
  const variables = ref(block.variables || []);
  const addCustomVariable = (values) => {
    variables.value.push(values);
  };

  // hide upstream vars for active block
  const hiddenUpstreamVariables = ref(block.hiddenUpstreamVariables || []);

  // Helper functions
  const helpers = ref(block.helpers || {});

  const defaultMenu = [{ title: 'Delete', action: 'delete', icon: 'fal fa-trash' }];
  const overflowMenu = ref([]);
  const generateOverflowMenu = () => {
    const definitionOverflow = block.overflowMenu || [];
    if (isRoot.value) overflowMenu.value = definitionOverflow;
    else overflowMenu.value = [...definitionOverflow, ...defaultMenu];
  };

  const getUpstreamData = () => {
    const upstreamValues = [];
    let node = parent.value;
    while (node) {
      upstreamValues.push(node);
      node = node.parent;
    }
    return upstreamValues;
  };

  // Outcomes section
  const defaultOutcome = {
    isOutcome: true,
    outcomeId: 0,
    title: null,
    child: null,
  };
  const outcomes = ref([]);
  const generateOutcomes = () => {
    if (block.isDemo) return;
    const blockOutcomes = [];
    block.outcomes.forEach((outcome, index) => {
      const newOutcome = { ...defaultOutcome, ...outcome };
      newOutcome.outcomeIndex = index;
      newOutcome.outcomeId = index;
      blockOutcomes.push(useOutcome(newOutcome, selfRef.value));
    });
    outcomes.value = blockOutcomes;
  };

  // Settings Config
  const moduleSettingsList = ref(block.moduleSettingsList || []);
  const generalSettings = ref({
    ...allBlockSettings,
    title: block.title,
    description: block.description,
  });

  // Set the ID of a child outcome
  const setParent = (newParent) => (parent.value = newParent);
  const setOutcome = (outcomeId, child) => {
    const outcomeIndex = outcomes.value.findIndex((o) => o.outcomeId === outcomeId);
    child && (child.parentOutcome = outcomes.value[outcomeIndex]);
    outcomes.value[outcomeIndex].child = child;
    outcomes.value[outcomeIndex].childId = child?.id;
  };
  const getOutcomeById = (outcomeId) => outcomes.value.find((o) => o.id === outcomeId);

  const loadSettings = () => {
    if ('onSettingsLoaded' in actions.value) actions.value?.onSettingsLoaded(selfRef.value);
  };

  // Blocks internal handling of activate state
  const activate = () => {
    if (actions.value?.onActivate) actions.value?.onActivate(selfRef.value);
  };

  // Initialize the block and store a ref to itself. Initializes the block's default outcomes.
  const init = (selfRefBlock) => {
    selfRef.value = selfRefBlock;
    friendlyId.value = selfRefBlock.friendlyId;
    indexOfType.value = selfRefBlock.indexOfType;

    // Assign block titles incrementally if multiple blocks of the same type are created
    if (selfRefBlock.generalSettings.title === title.value && indexOfType.value > 1) {
      selfRefBlock.generalSettings.title = `${title.value} ${indexOfType.value}`;
    }

    generateOutcomes();
    generateOverflowMenu();
    if (block.actions?.onInit) block.actions.onInit(selfRef.value);
  };
  const load = (block, isCopying) => {
    !isCopying && (id.value = block.id);
    generalSettings.value = block.general_settings;
    variables.value = block.variables;
    overflowMenu.value = block.overflowMenu;
    isRoot.value = block.is_root;
  };

  const save = () => {
    return {
      id: id.value,
      friendly_id: friendlyId.value,
      is_root: isRoot.value,
      title: title.value,
      key: key.value,
      path: path.value || [],
      variables: variables.value,
      outcomes: outcomes.value.map((o) => o.save()),
      general_settings: generalSettings.value,
      overflowMenu: overflowMenu.value,
    };
  };

  return {
    friendlyId,
    id,
    key,
    level,
    parent,
    path,
    element,
    isRoot,
    isDemo,
    isFromOrgWideGroup,
    quickButtonText,
    quickButtonAction,
    domElement,
    overflowMenu,
    workflow, 
    
    // Helper functions from the block definition
    helpers,

    blockWidth,
    blockSpacing,
    blockHeight,

    x,
    parentOutcome,

    state,
    internalState,
    inputs,
    variables,
    hiddenUpstreamVariables,
    actions,

    title,
    indexOfType,
    blockTitle,
    subtitleIcon,
    icon,
    lightColor,
    darkColor,
    thickBorder,
    thinBorder,

    location,

    outcomes,

    moduleSettingsList,
    generalSettings,
    showLoading,

    init,
    load,
    save,
    setParent,
    setOutcome,
    getOutcomeById,
    setDomElement,
    getDomElement,
    activate,
    loadSettings,
    addCustomVariable,
    setAsRoot,
    generateID,

    getUpstreamData,
  };
}
